import { AsideModal, Modal, Text } from 'src/ui/components'
import styles from './CalendarModal.module.scss'
import { FormProvider } from 'react-hook-form'
import { useDatesRangeForm } from 'src/ui/hooks/useDatesRangeForm'
import { FormEvent, useEffect, useState } from 'react'
import { CheckInCheckOut } from 'src/core/Shared/domain/CheckInCheckOut'
import { useTrans } from 'src/ui/hooks/useTrans'
import { createModal } from 'src/ui/hooks/useModal'
import { DatePickerProvider } from './DatePicker/DatePickerContext'
import { DatePicker } from './DatePicker'
import { useMedia } from 'src/ui/hooks/useMedia'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { AvailabilityTypeApplied } from 'src/core/Availability/domain/Availability.model'

interface Props {
  title: string
  hotelId: string
  market: string
  groupCode?: string
  checkIn: Date
  checkOut: Date
  onClose?: () => void
  onSubmit: (
    dates: CheckInCheckOut,
  ) => Promise<void | AvailabilityTypeApplied | undefined>
  initialError?: boolean
  mode?: 'funnel' | 'management'
}

export const CalendarModal = createModal(
  ({
    title,
    hotelId,
    market,
    groupCode,
    checkIn,
    checkOut,
    onClose,
    onSubmit,
    initialError = false,
    mode = 'funnel',
  }: Props) => {
    const { trans } = useTrans(['new-reservation', 'common'])
    const {
      methods: datesMethods,
      validateFields,
      setManualErrorMessage,
      resetForm,
      areValuesSameAsOld,
    } = useDatesRangeForm({
      checkIn,
      checkOut,
    })
    const [isLoadingAvailability, setIsLoadingAvailability] = useState(false)
    const { media } = useMedia()
    const isMobileOrTablet = media === 'mobile' || media === 'tablet'
    const isManagement = mode === 'management'

    useEffect(() => {
      if (!initialError) {
        return
      }

      setManualErrorMessage(trans('common:dates-modal_no-dispo-error'))
    }, [initialError])

    const handleSubmit = async (event: FormEvent) => {
      event.preventDefault()
      const { checkIn: newCheckIn, checkOut: newCheckOut } =
        datesMethods.getValues('dates')

      if (areValuesSameAsOld()) {
        if (isManagement) {
          if (isUndefined(newCheckIn)) {
            return setManualErrorMessage(
              trans('common:dates-range-form_select-check-in'),
            )
          }

          if (isUndefined(newCheckOut)) {
            return setManualErrorMessage(
              trans('common:dates-range-form_select-check-out'),
            )
          }

          return setManualErrorMessage(
            trans(
              'common:dates-range-form_select-dates-different-from-reservation',
            ),
          )
        }

        if (initialError) {
          if (isUndefined(newCheckIn)) {
            return setManualErrorMessage(
              trans('common:dates-range-form_select-check-in'),
            )
          }
          if (isUndefined(newCheckOut)) {
            return setManualErrorMessage(
              trans('common:dates-range-form_select-check-out'),
            )
          }

          return setManualErrorMessage(
            trans('common:dates-range-form_select-new-dates'),
          )
        }
      }

      if (isDefined(newCheckIn) && isDefined(newCheckOut)) {
        setIsLoadingAvailability(true)
      }
      await validateFields(onSubmit, onClose)
      setIsLoadingAvailability(false)
    }

    const handleReset = (event: FormEvent) => {
      event.preventDefault()

      resetForm()
    }

    const renderDatePicker = () => {
      return (
        <FormProvider {...datesMethods}>
          <DatePickerProvider
            hotelId={hotelId}
            market={market}
            groupCode={groupCode}
            isLoadingAvailability={isLoadingAvailability}
          >
            <form
              className={styles.form}
              onSubmit={handleSubmit}
              onReset={handleReset}
            >
              <DatePicker />
            </form>
          </DatePickerProvider>
        </FormProvider>
      )
    }

    return (
      <>
        {isMobileOrTablet ? (
          <AsideModal title={title} onClose={onClose}>
            <div className={styles.container}>{renderDatePicker()}</div>
          </AsideModal>
        ) : (
          <Modal
            size="xl"
            mobileStyle="fullscreen"
            type="transactional"
            data-testid="dates-modal"
            onClose={onClose}
          >
            <div className={styles.container}>
              <Text as="h1" fontStyle="2xl-700">
                {title}
              </Text>
              {renderDatePicker()}
            </div>
          </Modal>
        )}
      </>
    )
  },
)
