import { ReservationGuaranteeAndCancellationTimeline } from 'src/core/Shared/domain/Reservation.model'
import { GuaranteePolicy } from 'src/core/Shared/domain/Policies.model'
import { Translator } from '../hooks/useTrans'

const TRANSLATE_CONTEXT_KEY = 'common:guarantee_policy'

export const getGuaranteeTitle = (
  guarantee: GuaranteePolicy,
  timeline: ReservationGuaranteeAndCancellationTimeline,
  trans: Translator,
): string => {
  const key = guarantee.type.key
  const value = guarantee.type.value
  const options = value
    ? {
        value,
      }
    : undefined

  return trans(`${TRANSLATE_CONTEXT_KEY}_${timeline}_title_${key}`, options)
}

//TODO: a esta función se le quitará el V2 y se eliminará la de arriba cuando se cambien los títulos del paso 4
export const getGuaranteeTitleV2 = (
  guarantee: GuaranteePolicy,
  timeline: ReservationGuaranteeAndCancellationTimeline,
  trans: Translator,
): string => {
  const key = guarantee.type.key
  const value = guarantee.type.value
  const options = value
    ? {
        value,
      }
    : undefined

  return trans(`${TRANSLATE_CONTEXT_KEY}_${timeline}_title_${key}_v2`, options)
}
