import { FC } from 'react'
import { Text } from 'src/ui/components/atoms/Text'
import styles from './Day.module.scss'
import { DayVariant } from '../DatePickerContext'
import { Tooltip } from 'src/ui/components/molecules/Tooltip'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Icon } from 'src/ui/components/atoms/Icon'
import ArrowIcon from 'src/ui/components/atoms/Icon/_icons/navigation/ArrowIcon'
import { Time } from 'src/core/Shared/infrastructure/Time'
import { classNames } from 'src/ui/utils/classnames'
import { DayPriceRange } from 'src/core/Availability/domain/CalendarDayStatus.model'
import { DotsPriceRange } from '../PriceRange'

interface DayProps {
  date: Date
  variant?: DayVariant
  priceRange: DayPriceRange
  tabIndex?: number
}

const variantClassName = {
  open: styles.enabledDate,
  disabled: styles.disabledDate,
}

export const Day: FC<DayProps> = ({ date, variant, priceRange, tabIndex }) => {
  const currentDay = date.getDate()
  const testId = Time.fromDate(date).format('YYYY-MM-DD')

  const handleClick = (e: React.MouseEvent) => {
    if (variant?.status === 'disabled') {
      e.stopPropagation()
      e.preventDefault()
    }
  }
  const isTodayDisabled =
    variant?.status === 'disabled' && Time.isSameDay(date, new Date())
  const hasLevelPrice = priceRange !== 'EMPTY'

  if (isDefined(variant)) {
    const isDayDisabled = variant.status === 'disabled'

    if (isDefined(variant.tooltip)) {
      return (
        <Tooltip
          tooltipContent={variant.tooltip}
          triggerElement={
            <>
              <Text
                as="div"
                fontStyle="s-700"
                color="unset"
                className={variantClassName[variant.appearance]}
                ariaLabel={variant.tooltip}
              >
                {currentDay}
              </Text>
              <DayIcon variant={variant} />
              {hasLevelPrice && <DotsPriceRange priceRange={priceRange} />}
            </>
          }
          triggerButton={{
            className: styles.dayButton,
            ariaDisabled: isDayDisabled,
            testId,
            onClick: handleClick,
            tabIndex,
          }}
        />
      )
    }

    return (
      <button
        type="button"
        onClick={handleClick}
        className={classNames(
          isTodayDisabled && styles.disabledToday,
          styles.dayButton,
        )}
        aria-disabled={isDayDisabled}
        data-testid={testId}
        tabIndex={tabIndex}
      >
        <Text
          as="div"
          fontStyle="s-700"
          color="unset"
          className={variantClassName[variant.appearance]}
        >
          {currentDay}
        </Text>
        <DayIcon variant={variant} />
        {hasLevelPrice && <DotsPriceRange priceRange={priceRange} />}
      </button>
    )
  }

  return (
    <Text
      as="div"
      className={styles.dayText}
      fontStyle="s-700"
      tabIndex={tabIndex}
    >
      {currentDay}
    </Text>
  )
}

const DayIcon: FC<{ variant: DayVariant }> = ({ variant }) => {
  if (variant.icon === 'arrowOut') {
    return (
      <Icon
        icon={ArrowIcon}
        className={styles.arrowOutIcon}
        size="2xs"
        rotate={135}
        data-testid="arrowOutIcon"
        ariaHidden
      />
    )
  }

  if (variant.icon === 'arrowIn') {
    return (
      <Icon
        icon={ArrowIcon}
        className={styles.arrowInIcon}
        size="2xs"
        rotate={225}
        data-testid="arrowInIcon"
        ariaHidden
      />
    )
  }

  return null
}
