import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useFormContext } from 'react-hook-form'
import { useLanguageConfig } from 'src/ui/contexts/LanguageConfigContext'
import { DatesFormValues } from 'src/ui/hooks/useDatesRangeForm'
import { isDayDisabled } from './isDayDisabled'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import {
  areTwoMonthStatusLoaded,
  CalendarDayStatus,
  DayPriceRange,
  getFirstMonthNotLoaded,
  createRestrictionsAttributes,
} from 'src/core/Availability/domain/CalendarDayStatus.model'
import { container } from 'src/core/Shared/_di'
import { Time } from 'src/core/Shared/infrastructure/Time'
import { useTrans } from 'src/ui/hooks/useTrans'

export interface DayVariant {
  status: 'enabled' | 'disabled'
  appearance: 'open' | 'disabled'
  tooltip?: string
  icon?: 'arrowOut' | 'arrowIn'
}

interface DatePickerContext {
  isLoading: boolean
  checkIn?: Date
  checkOut?: Date
  minDate: Date
  maxDate: Date
  locale: string
  range: Date[]
  daysStatusToShow: CalendarDayStatus[]
  changeVisibleMonths: (startMonth: Date, endMonth: Date) => Promise<void>
  getDayVariant: (day: Date) => DayVariant
  getPriceRange: (day: Date) => DayPriceRange
  hasDayRestrictionToGroupCode: boolean
}

const defaultDatePickerContext: DatePickerContext = {
  isLoading: false,
  checkIn: new Date(),
  checkOut: undefined,
  minDate: new Date(),
  maxDate: new Date(),
  locale: 'en',
  range: [],
  daysStatusToShow: [],
  changeVisibleMonths: async () => {},
  getDayVariant: () => ({ status: 'enabled', appearance: 'open' }),
  getPriceRange: () => 'EMPTY',
  hasDayRestrictionToGroupCode: false,
}

export const DatePickerContext = createContext<DatePickerContext>(
  defaultDatePickerContext,
)

interface DatePickerProviderProps {
  hotelId: string
  market: string
  groupCode?: string
  children: ReactNode
  isLoadingAvailability?: boolean
}

/***
 * Este provider necesita estar dentro de un FormProvider para que pueda acceder a los valores del formulario
 */
export const DatePickerProvider: FC<DatePickerProviderProps> = ({
  hotelId,
  market,
  groupCode,
  children,
  isLoadingAvailability = false,
}) => {
  const { trans } = useTrans(['common'])
  const [isLoading, setIsLoading] = useState(true)
  const [visibleMonths, setVisibleMonths] = useState({
    start: new Date(),
    end: new Date(),
  })
  const [daysStatus, setDaysStatus] = useState<CalendarDayStatus[]>([])
  const [daysStatusToShow, setDaysStatusToShow] = useState<CalendarDayStatus[]>(
    [],
  )
  const [hasDayRestrictionToGroupCode, setHasDayRestrictionToGroupCode] =
    useState(false)
  const { watch } = useFormContext<DatesFormValues>()
  const checkIn = watch('dates.checkIn')
  const checkOut = watch('dates.checkOut')

  const languageConfig = useLanguageConfig()
  const locale = languageConfig.properties.culture

  const maxDate = datesManager.getMaxDate()
  const minDate = checkIn && !checkOut ? checkIn : new Date()

  const range = [checkIn, checkOut].filter(isDefined)
  const isDefinedOnlyCheckIn = isDefined(checkIn) && isUndefined(checkOut)
  const isRangeDefined = isDefined(checkIn) && isDefined(checkOut)
  const isAllRangeUndefined = isUndefined(checkIn) && isUndefined(checkOut)

  const changeCurrentMonth = useCallback(
    async (month: Date) => {
      let result: CalendarDayStatus[] = []
      if (!areTwoMonthStatusLoaded(daysStatus, month)) {
        try {
          setIsLoading(true)
          const firstMonthToLoad = getFirstMonthNotLoaded(daysStatus, month)
          result = await container.resolve('getTwoMonthDaysStatus')({
            hotelId,
            month: firstMonthToLoad,
            market,
            groupCode,
          })
          if (result.length > 0) {
            setDaysStatus(prev => [...prev, ...result])
            setHasDayRestrictionToGroupCode(
              result.some(
                status => status.restriction.status === 'CLOSED_TO_GROUP_CODE',
              ),
            )
          }
        } finally {
          setIsLoading(false)
        }
      }
      const monthToLoad = Time.fromDate(month).toDate().getMonth()
      setDaysStatusToShow(
        [...daysStatus, ...result].filter(status => {
          const date = Time.fromDate(status.date).toDate()
          const month = date.getMonth()

          return month === monthToLoad || month === (monthToLoad + 1) % 12
        }),
      )
    },
    [daysStatus, hotelId, market, groupCode],
  )

  const changeVisibleMonths = useCallback(
    async (startMonth: Date, endMonth: Date) => {
      await changeCurrentMonth(startMonth)
      setVisibleMonths({ start: startMonth, end: endMonth })
    },
    [changeCurrentMonth],
  )

  const findStatusFor = useMemo(
    () => (day: Date) =>
      daysStatusToShow.find(status => Time.isSameDay(status.date, day)),
    [daysStatusToShow],
  )

  const getPriceRange = useMemo(
    () =>
      (day: Date): DayPriceRange => {
        if (hasDayRestrictionToGroupCode) {
          return 'EMPTY'
        }
        const dayStatus = daysStatusToShow.find(dayStatus =>
          Time.isSameDay(day, dayStatus.date),
        )

        if (isUndefined(dayStatus) || isUndefined(dayStatus.priceRange)) {
          return 'EMPTY'
        }

        return dayStatus.priceRange
      },
    [daysStatusToShow, hasDayRestrictionToGroupCode],
  )

  const getDayVariant = (day: Date): DayVariant => {
    if (hasDayRestrictionToGroupCode(day)) {
      return {
        status: 'disabled',
        appearance: 'disabled',
        tooltip: trans('calendar_restriction-message_closed-to-group-code'),
      }
    }

    if (isDayDisabled({ day, checkIn, checkOut })) {
      return { status: 'disabled', appearance: 'disabled' }
    }

    if (isDefinedOnlyCheckIn) {
      if (isNoDepartureRestriction(day)) {
        if (Time.isSameDay(checkIn, day)) {
          return {
            status: 'enabled',
            appearance: 'open',
            icon: 'arrowIn',
          }
        }
        return {
          status: 'disabled',
          appearance: 'open',
          icon: 'arrowIn',
          tooltip: trans('calendar_restriction-message_no-departure'),
        }
      }

      if (isDayAffectedByMinStay(day)) {
        const status = findStatusFor(checkIn)
        return {
          status: 'disabled',
          appearance: 'disabled',
          tooltip: trans('calendar_restriction-message_min-stay', {
            count: status?.restriction.quantity,
          }),
        }
      }

      if (isDayAffectedByMaxStay(day)) {
        const status = findStatusFor(checkIn)
        return {
          status: 'disabled',
          appearance: 'disabled',
          tooltip: trans('calendar_restriction-message_max-stay', {
            count: status?.restriction.quantity,
          }),
        }
      }

      if (isDayAffectedByClosed(day)) {
        return {
          status: 'disabled',
          appearance: 'disabled',
          tooltip: trans('calendar_restriction-message_closed'),
        }
      }

      if (isMinStayDayButNotCheckIn(day)) {
        return {
          status: 'enabled',
          appearance: 'open',
        }
      }
    }

    if (hasNoArrivalRestriction(day)) {
      return {
        status: 'disabled',
        appearance: 'open',
        icon: 'arrowOut',
        tooltip: trans('calendar_restriction-message_no-arrival'),
      }
    }

    if (hasClosedRestriction(day)) {
      const dayBefore = Time.fromDate(day).subtract(1, 'day').toDate()
      const dayBeforeStatus = findStatusFor(dayBefore)
      if (
        Time.isSameDay(checkOut, day) &&
        dayBeforeStatus?.restriction.status !== 'CLOSED'
      ) {
        return {
          status: 'enabled',
          appearance: 'open',
        }
      }

      return {
        status: 'disabled',
        appearance: 'disabled',
        tooltip: trans('calendar_restriction-message_closed'),
      }
    }

    if (isNoDepartureRestriction(day)) {
      return {
        status: 'enabled',
        appearance: 'open',
        icon: 'arrowIn',
        tooltip: trans('calendar_restriction-message_no-departure'),
      }
    }

    if (hasMinStayRestriction(day)) {
      if (Time.isSameDay(checkOut, day)) {
        return {
          status: 'enabled',
          appearance: 'open',
        }
      }

      const status = findStatusFor(day)
      return {
        status: 'enabled',
        appearance: 'open',
        tooltip: trans('calendar_restriction-message_min-stay', {
          count: status?.restriction.quantity,
        }),
      }
    }

    if (hasMaxStayRestriction(day)) {
      if (isDefinedOnlyCheckIn && Time.isDifferentDay(checkIn, day)) {
        return {
          status: 'enabled',
          appearance: 'open',
        }
      }

      const status = findStatusFor(day)
      return {
        status: 'enabled',
        appearance: 'open',
        tooltip: trans('calendar_restriction-message_max-stay', {
          count: status?.restriction.quantity,
        }),
      }
    }

    if ((isRangeDefined || isAllRangeUndefined) && isNoArrivalDay(day)) {
      return {
        status: 'disabled',
        appearance: 'open',
        icon: 'arrowOut',
        tooltip: trans('calendar_restriction-message_no-arrival'),
      }
    }

    return { status: 'enabled', appearance: 'open' }

    function hasDayRestrictionToGroupCode(day: Date) {
      const dayStatus = findStatusFor(day)
      if (isUndefined(dayStatus)) {
        return false
      }

      return dayStatus.restriction.status === 'CLOSED_TO_GROUP_CODE'
    }

    function hasClosedRestriction(day: Date) {
      const dayStatus = findStatusFor(day)
      const isClosedDay = dayStatus?.restriction.status === 'CLOSED'

      if (!isClosedDay) {
        return false
      }

      if (isRangeDefined || isAllRangeUndefined) {
        return true
      }

      if (day > checkIn!) {
        const dayBefore = Time.fromDate(day).subtract(1, 'day').toDate()
        const dayBeforeStatus = findStatusFor(dayBefore)
        return !(
          dayBefore >= checkIn! &&
          dayBeforeStatus?.restriction.status !== 'CLOSED'
        )
      }

      return false
    }

    function isDayAffectedByClosed(day: Date) {
      if (isUndefined(checkIn)) {
        return false
      }

      return daysStatusToShow.some(
        status =>
          status.restriction.status === 'CLOSED' &&
          status.date > checkIn &&
          status.date < day,
      )
    }

    function hasMinStayRestriction(day: Date) {
      const dayStatus = findStatusFor(day)
      return isDefined(dayStatus) && dayStatus.restriction.status === 'MIN_STAY'
    }

    function isMinStayDayButNotCheckIn(day: Date) {
      if (isUndefined(checkIn)) {
        return false
      }

      const dayStatus = findStatusFor(day)
      return (
        isDefined(dayStatus) &&
        dayStatus.restriction.status === 'MIN_STAY' &&
        Time.isDifferentDay(checkIn, day)
      )
    }

    function isDayAffectedByMinStay(day: Date) {
      if (isUndefined(checkIn)) {
        return false
      }

      const checkInStatus = findStatusFor(checkIn)
      return (
        isDefined(checkInStatus) &&
        isDefined(checkInStatus.restriction.quantity) &&
        checkInStatus.restriction.status === 'MIN_STAY' &&
        checkIn < day &&
        Time.fromDate(checkIn)
          .add(checkInStatus.restriction.quantity, 'day')
          .toDate() > day
      )
    }

    function hasMaxStayRestriction(day: Date) {
      const dayStatus = findStatusFor(day)
      return isDefined(dayStatus) && dayStatus.restriction.status === 'MAX_STAY'
    }

    function isDayAffectedByMaxStay(day: Date) {
      if (isUndefined(checkIn)) {
        return false
      }

      const checkInStatus = findStatusFor(checkIn)
      if (
        checkInStatus?.restriction.status !== 'MAX_STAY' ||
        isUndefined(checkInStatus.restriction.quantity)
      ) {
        return false
      }

      const maxStayDay = Time.fromDate(checkIn)
        .add(checkInStatus.restriction.quantity, 'day')
        .toDate()

      return day > maxStayDay
    }

    function hasNoArrivalRestriction(day: Date) {
      if (isDefinedOnlyCheckIn) {
        return false
      }

      const dayStatus = findStatusFor(day)
      const nextDay = Time.fromDate(day).add(1, 'day').toDate()
      const nextDayStatus = findStatusFor(nextDay)

      if (isDefined(checkIn) && isDefined(checkOut)) {
        const checkOutStatus = findStatusFor(checkOut)
        if (checkOutStatus?.restriction.status === 'NO_ARRIVAL') {
          return false
        }
      }

      if (dayStatus?.restriction.status === 'NO_ARRIVAL') {
        return true
      }

      if (
        (isUndefined(dayStatus) || dayStatus?.restriction.status === 'OPEN') &&
        nextDayStatus?.restriction.status === 'CLOSED' &&
        Time.isDifferentDay(day, checkOut)
      ) {
        return true
      }

      return false
    }

    function isNoArrivalDay(day: Date) {
      const dayStatus = findStatusFor(day)
      const nextDay = Time.fromDate(day).add(1, 'day').toDate()
      const nextDayStatus = findStatusFor(nextDay)

      if (dayStatus?.restriction.status === 'NO_ARRIVAL') {
        return true
      }

      if (
        (isUndefined(dayStatus) || dayStatus?.restriction.status === 'OPEN') &&
        nextDayStatus?.restriction.status === 'CLOSED'
      ) {
        return true
      }

      return false
    }

    function isNoDepartureRestriction(day: Date) {
      /*
       * La restricción "NO_DEPARTURE" para el día de hoy viene definida como
       * restricción del día anterior. Ejemplo: día 09/10/24 tiene una
       * restricción NO_DEPARTURE, el día que no se puede será el 10/10/24
       */
      const dayToSearchRestriction = Time.fromDate(day)
        .subtract(1, 'day')
        .toDate()

      const dayStatus = findStatusFor(dayToSearchRestriction)
      return dayStatus?.restriction.status === 'NO_DEPARTURE'
    }
  }

  const visibleRestrictions = useMemo(
    () =>
      createRestrictionsAttributes(
        daysStatusToShow,
        visibleMonths.start,
        visibleMonths.end,
      ),
    [visibleMonths, daysStatusToShow],
  )

  return (
    <DatePickerContext.Provider
      value={{
        isLoading: isLoading || isLoadingAvailability,
        checkIn,
        checkOut,
        locale,
        getDayVariant,
        minDate,
        maxDate,
        range,
        daysStatusToShow,
        changeVisibleMonths,
        getPriceRange,
        hasDayRestrictionToGroupCode,
      }}
    >
      <div className="h-full" {...visibleRestrictions}>
        {children}
      </div>
    </DatePickerContext.Provider>
  )
}

export const useDatePicker = () => useContext(DatePickerContext)
