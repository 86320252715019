import {
  Calendar as CalendarComponent,
  CalendarProps,
  MapDaysProps,
} from 'react-multi-date-picker'
import {
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  PropsWithoutRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'

const RMDP_DAY_SELECTOR = '.rmdp-day'
const SPACE_CODE = 'Space'
const SPACE_KEY = ' '

type Props<
  Multiple extends boolean = false,
  Range extends boolean = false,
> = PropsWithoutRef<CalendarProps<Multiple, Range>>

export const Calendar = forwardRef(function Calendar<
  Multiple extends boolean = false,
  Range extends boolean = false,
>(
  { mapDays, ...props }: Props<Multiple, Range>,
  outerRef: ForwardedRef<unknown>,
) {
  const [ref, setRef] = useState<HTMLElement>()
  useImperativeHandle(outerRef, () => ref, [ref])
  const updateRef = useCallback(
    (element: HTMLElement) => {
      if (element) {
        fixCalendarAccesibility(element)
      }
      setRef(element)
    },
    [setRef],
  )
  const customMapDays = useCallback(
    (props: MapDaysProps<Multiple, Range>) => ({
      ...mapDays?.(props),
      ref: handleDayElement,
    }),
    [mapDays],
  )

  return (
    <CalendarComponent<Multiple, Range>
      {...props}
      // Aunque a nivel de tipado no lo contempla, también admite que se le pase una función
      ref={updateRef as unknown as MutableRefObject<HTMLElement>}
      mapDays={customMapDays}
    />
  )
})

const fixCalendarAccesibility = (element: HTMLElement) => {
  element
    .querySelectorAll('[data-active="true"]')
    .forEach(element => element.setAttribute('data-active', 'false'))
}

const handleDayElement = (element: HTMLElement | null) => {
  const parent = element?.closest<HTMLElement>(RMDP_DAY_SELECTOR)
  parent?.addEventListener('keydown', handleKeyDown, { capture: true })
}

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.code !== SPACE_CODE && event.key !== SPACE_KEY) {
    event.stopImmediatePropagation()
  }
}
