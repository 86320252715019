import { FC } from 'react'
import { classNames } from 'src/ui/utils/classnames'
import styles from './DotPriceRange.module.scss'
import { isUndefined } from 'lodash'
import { Flex } from 'src/ui/styles/objects/Flex'
import { useTrans } from 'src/ui/hooks/useTrans'

interface Props {
  priceRange: string
}

export const DotsPriceRange: FC<Props> = ({ priceRange }) => {
  const { trans } = useTrans(['new-reservation'])
  if (isUndefined(priceRange)) {
    return null
  }

  const dots = {
    LOW: 1,
    MEDIUM: 2,
    HIGH: 3,
  }[priceRange]

  return (
    <Flex
      gap="xxs"
      justifyContent="center"
      ariaLabel={trans(`calendar_price-range-${priceRange.toLowerCase()}`)}
      className="dots"
    >
      {[...Array(dots)].map((_, index) => (
        <div
          key={index}
          className={classNames(
            styles.dot,
            styles[`dot_${priceRange.toLowerCase()}`],
          )}
        />
      ))}
    </Flex>
  )
}
